


export default function Root() {
    const url = window.location.href
    window.location.href = url.replace("tags","report").replace(".com/",".com/item/")
    return (
        <>
           <h2>Hello</h2>
        </>
    )
}