import React from 'react';
import { useRouteError } from 'react-router-dom';


export default function ErrorPage() {

    const error = useRouteError() as any

    console.error(error)

    return (
<></>
    )
}